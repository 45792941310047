import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        toolbar: {
            visible: false,
            enter: true
        },
        sortInfo: [],
        currentUser: {},
        currentAdmin: {},
        webInfo: {
            webName: "",
            webTitle: [],
            notices: [],
            footer: "",
            backgroundImage: "",
            avatar: ""
        }
    },
    getters: {
        articleTotal: state => {
            if (state.sortInfo !== null && state.sortInfo.length !== 0) {
                if (state.sortInfo.length === 1) {
                    return state.sortInfo[0].countOfSort;
                } else {
                    return state.sortInfo.reduce((prev, curr) => {
                        if (typeof prev === "number") {
                            return prev + curr.countOfSort;
                        } else {
                            return prev.countOfSort + curr.countOfSort;
                        }
                    });
                }
            } else {
                return 0;
            }
        },
        navigationBar: state => {
            if (state.sortInfo !== null && state.sortInfo.length !== 0) {
                return state.sortInfo.filter(f => f.sortType === 0);
            } else {
                return [];
            }
        }
    },
    mutations: {
        changeToolbarStatus(state, toolbarState) {
            state.toolbar = toolbarState;
        },
        loadSortInfo(state, sortInfo) {
            if (sortInfo !== null && sortInfo.length !== 0) {
                state.sortInfo = sortInfo.sort((s1, s2) => s1.priority - s2.priority);
            }
        },
        loadCurrentUser(state, user) {
            state.currentUser = user;
        },
        loadCurrentAdmin(state, user) {
            state.currentAdmin = user;
        },
        loadWebInfo(state, webInfo) {
            webInfo.webTitle = webInfo.webTitle.split('');
            webInfo.notices = JSON.parse(webInfo.notices);
            state.webInfo = webInfo;
        }
    },
    actions: {},
    modules: {},
    plugins: [
        createPersistedState({
            storage: window.localStorage
        })
    ]
})